import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setContacts,
  setPrecontacts,
} from "../../store/BusinessCardStore";
import { getContacts, getPrecontacts, getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { ContactData, PrecontactData } from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import PrecontactRow from "../../components/PrecontactRow/PrecontactRow";

const PrecontactList: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const precontacts = useStoreState(BusinessCardStore, getPrecontacts);

  const redirect = useNavigate();

  const [page, setPage] = useState(0);

  //   useEffect(() => {
  //     getContactForCurrentPage();
  //   }, []);

  useEffect(() => {
    getPrecontactForCurrentPage();
  }, [page]);

  const getPrecontactForCurrentPage = async () => {
    await axiosHelper
      .getPrecontactForPages(page)
      .then((response: PrecontactData[]) => {
        // console.log(response);
        setPrecontacts(response);
      });
  };

  return (
    <>
      <Header user={user.username} page={"LISTA PRECONTATTI"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start ">
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            {precontacts.map((item: PrecontactData) => (
              <PrecontactRow precontact={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PrecontactList;
