import { useNavigate } from "react-router";
import DLCLogo from "../../assets/LOGO_FINALE1.png";

import styles from "./Header.module.scss";

interface HeaderProps {
  user: string;
  page: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const redirect = useNavigate();

  return (
    <>
      <div
        className={`col-12 d-flex flex-row align-items-center  p-5 ${styles.headerDiv}`}
      >
        <div className="col-3 d-flex flex-row align-items-center justify-content-start fw-bold">
          {props.page}
        </div>
        <div
          className={`col-6 d-flex flex-row align-items-center justify-content-center fw-bold ${styles.logoContainer}`}
        >
          <img
            className="cursor-pointer"
            src={DLCLogo}
            onClick={() => redirect("/")}
          />
        </div>

        <div className="col-3 d-flex flex-row align-items-center justify-content-end fw-bold">
          {props.user}
        </div>
      </div>
    </>
  );
};

export default Header;
