import { createSelector } from "reselect";
import { IBusinessCardStore } from "./BusinessCardStore";

const getBusinessCardState = (state: IBusinessCardStore) => state;

export const getUser = createSelector(
  getBusinessCardState,
  (state) => state.user
);
export const getContacts = createSelector(
  getBusinessCardState,
  (state) => state.contacts
);
export const getCurrentContact = createSelector(
  getBusinessCardState,
  (state) => state.currentContact
);
export const getPrecontacts = createSelector(
  getBusinessCardState,
  (state) => state.precontacts
);

export const getCurrentPrecontact = createSelector(
  getBusinessCardState,
  (state) => state.currentPrecontact
);
