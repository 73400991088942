import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import styles from "./UpdateContact.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setContacts,
  setCurrentContact,
} from "../../store/BusinessCardStore";
import { getCurrentContact, getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useForm } from "react-hook-form";
import { ContactData, uploadImageData } from "../../global";
import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate, useParams } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Dropzone from "react-dropzone";
import { Modal } from "react-bootstrap";

const UpdateContact: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const currentContact = useStoreState(BusinessCardStore, getCurrentContact);
  const [error, setError] = useState("");
  const [whatsapp, setWhatsapp] = useState(0);
  const params = useParams();
  const redirect = useNavigate();
  const [contact, setContact] = useState<ContactData>();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [workmail, setWorkmail] = useState("");
  const [role, setRole] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [company, setCompany] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [label_link1, setLabel_link1] = useState("");
  const [label_link2, setLabel_link2] = useState("");
  const [mode, setMode] = useState("");
  const [logo, setLogo] = useState<File>();
  const [image, setImage] = useState<File>();
  const [uploadedImage, setUploadedImage] = useState("");
  const [uploadedLogo, setUploadedLogo] = useState("");

  const [popup, setPopup] = useState(false);
  const [phrase, setPhrase] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactData>();

  const onSubmit = async (data: ContactData) => {
    if (params.uuid) {
      if (whatsapp) {
        data.whatsapp = 1;
      } else {
        data.whatsapp = 0;
      }
      console.log(data);
      data.name = name;
      data.surname = surname;
      data.email = email;
      data.workmail = workmail;
      data.phone = phone;
      data.website = website;
      data.role = role;
      data.linkedin = linkedin;
      data.instagram = instagram;
      data.facebook = facebook;
      data.company = company;
      data.mode = mode;
      data.uuid = params.uuid;
      data.link1 = link1;
      data.link2 = link2;
      data.label_link1 = label_link1;
      data.label_link2 = label_link2;
      console.log(data);

      await axiosHelper.updateContact(data).then((response) => {
        console.log(response);
        redirect("/List");
      });
      // await axiosHelper
      //   .newContact(data)
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };

  useEffect(() => {
    if (params.uuid) {
      getContactFromParams();
    }
  }, [params.uuid]);

  const getContactFromParams = async () => {
    if (params.uuid) {
      await axiosHelper
        .getContactByUuid(params.uuid)
        .then((response: ContactData) => {
          console.log(response);
          setContact(response);
          setCurrentContact(response);
        });
    }
  };

  useEffect(() => {
    if (contact) {
      setName(contact.name);
      setSurname(contact.surname);
      setEmail(contact.email);
      setPhone(contact.phone);
      setWebsite(contact.website);
      setWorkmail(contact.workmail);
      setRole(contact.role);
      setInstagram(contact.instagram);
      setFacebook(contact.facebook);
      setLinkedin(contact.linkedin);
      setWhatsapp(contact.whatsapp);
      setCompany(contact.company);
      setLink1(contact.link1);
      setLabel_link1(contact.label_link1);
      setLink2(contact.link2);
      setLabel_link2(contact.label_link2);
      setMode(contact.mode);
      setUploadedImage(contact.imageurl);
      setUploadedLogo(contact.logourl);
    }
  }, [contact]);

  const onDropImage = (e: any) => {
    console.log(e?.[0]);
    setImage(e?.[0]);
  };

  const onDropLogo = (e: any) => {
    console.log(e?.[0]);
    setLogo(e?.[0]);
  };

  const uploadImage = async () => {
    if (image) {
      const data: uploadImageData = {
        uuid: currentContact.uuid,
        image: image,
      };
      await axiosHelper
        .addContactImage(data)
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    }
  };

  const uploadLogo = async () => {
    if (logo) {
      const data: uploadImageData = {
        uuid: currentContact.uuid,
        image: logo,
      };
      await axiosHelper
        .addContactLogo(data)
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    }
  };

  const deleteContact = async () => {
    if (phrase === "elimina definitivamente") {
      await axiosHelper
        .deleteContact(contact?.uuid, user.id)
        ?.then(async (response: any) => {
          console.log(response);
          await axiosHelper.getContactForPages(0).then((response) => {
            setContacts(response);
            redirect("/List");
          });
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <Header user={user.username} page={"UPDATE CONTACT"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" col-12 rounded color-black "
          >
            <div className=" d-flex flex-row  col-12 align-items-center justify-content-center mt-7 ">
              <span
                className="rounded d-flex flex-row align-items-center justify-content-center col-7 btn-red "
                onClick={() => setPopup(!popup)}
              >
                DELETE CONTACT
              </span>
              <Modal
                show={popup}
                onHide={() => setPopup(!popup)}
                className={`d-flex flex-column justify-content-center `}
                contentClassName={` ${styles.rounded}`}
              >
                <div
                  className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}
                >
                  <Modal.Body className="d-flex flex-column  justify-content-center p-3">
                    <div className="col-12  ">
                      Sei sicuro di cancellare il contatto di {name}, {surname}
                    </div>
                    <div className="col-12 d-flex flex-row mt-4 align-items-center justify-content-center ">
                      <span>
                        Scrivi <i>elimina definitivamente</i> per poter
                        eliminare il contatto.
                      </span>
                    </div>
                    <div className="col-12 d-flex flex-row mt-4 align-items-center justify-content-center ">
                      <input
                        type="text "
                        style={{ borderRadius: "10px" }}
                        onChange={(e) => setPhrase(e.target.value)}
                      />
                    </div>
                    <div className="col-12 d-flex flex-row mt-4 align-items-center justify-content-around ">
                      <div
                        className="btn-danger rounded col-3 "
                        onClick={() => setPopup(!popup)}
                      >
                        Back
                      </div>
                      <div
                        className="btn-success rounded col-4"
                        onClick={() => deleteContact()}
                      >
                        Elimina contatto
                      </div>
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div>
            <div className=" d-flex flex-row  col-12 align-items-center justify-content-center mt-7 ">
              <span className="bold-title"> UPDATE CONTACT</span>
              <span className="sub-title">{contact?.uuid}</span>
            </div>
            <div className=" d-flex flex-column  pb-5 align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8   me-5">
                name :
              </div>
              <div className="col-7 ">
                <input
                  value={name}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("name"),
                  { onChange: (e) => setName(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                surname :
              </div>
              <div className="col-7 ">
                <input
                  value={surname}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("surname"),
                  { onChange: (e) => setSurname(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                email :
              </div>
              <div className="col-7 ">
                <input
                  value={email}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("email"),
                  { onChange: (e) => setEmail(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                phone :{" "}
                <span className="ms-3 fw-normal">
                  (tutto attaccato con prefisso +..)
                </span>
              </div>
              <div className="col-7 ">
                <input
                  value={phone}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("phone"),
                  { onChange: (e) => setPhone(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                whatsapp :{" "}
                <Switch
                  className="ms-5"
                  checked={whatsapp === 1 ? true : false}
                  onChange={() => {
                    whatsapp === 1 ? setWhatsapp(0) : setWhatsapp(1);
                  }}
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                workmail :
              </div>
              <div className="col-7 ">
                <input
                  value={workmail}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("workmail"),
                  { onChange: (e) => setWorkmail(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                role :
              </div>
              <div className="col-7 ">
                <input
                  value={role}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("role"),
                  { onChange: (e) => setRole(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                website :
              </div>
              <div className="col-7 ">
                <input
                  value={website}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("website"),
                  { onChange: (e) => setWebsite(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                linkedin :
              </div>
              <div className="col-7 ">
                <input
                  value={linkedin}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("linkedin"),
                  { onChange: (e) => setLinkedin(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                facebook :
              </div>
              <div className="col-7 ">
                <input
                  value={facebook}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("facebook"),
                  { onChange: (e) => setFacebook(e.target.value) })}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                instagram :
              </div>
              <div className="col-7 ">
                <input
                  value={instagram}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("instagram"),
                  { onChange: (e) => setInstagram(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                company :
              </div>
              <div className="col-7 ">
                <input
                  value={company}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("company"),
                  { onChange: (e) => setCompany(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                link 1 :
              </div>
              <div className="col-7 ">
                <input
                  value={link1}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("link1"),
                  { onChange: (e) => setLink1(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                Label link 1 :
              </div>
              <div className="col-7 ">
                <input
                  value={label_link1}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("label_link1"),
                  { onChange: (e) => setLabel_link1(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                link 2 :
              </div>
              <div className="col-7 ">
                <input
                  value={link2}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("link2"),
                  { onChange: (e) => setLink2(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                Label link 2 :
              </div>
              <div className="col-7 ">
                <input
                  value={label_link2}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("label_link2"),
                  { onChange: (e) => setLabel_link2(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  mb-3">
                mode : ( NIGHT, LIGHT )
              </div>
              <div className="col-7 ">
                <input
                  value={mode}
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...(register("mode"),
                  { onChange: (e) => setMode(e.target.value) })}
                />
              </div>
            </div>

            <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 mb-7  ">
              <button className="btn-success col-8 rounded">
                UPDATE CONTACT
              </button>
            </div>
            {error !== "" && (
              <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
                <span className=" text-16">{error}</span>
              </div>
            )}
          </form>

          <div className=" col-12 d-flex flex-row align-items-center justify-content-center">
            <span className="sub-title ">Immagine:</span>
          </div>
          {!image && !uploadedImage && (
            <>
              <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-5 pb-5">
                <div
                  className=" mt-3 bg-light col-7 d-flex flex-row align-items-center justify-content-center"
                  style={{
                    border: "1px dashed black",
                    borderRadius: "20px",
                  }}
                >
                  <Dropzone
                    onDrop={onDropImage}
                    accept={"image/png, image/jpg, image/jpeg"}
                    maxSize={31457280}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className={` bg-light d-flex align-items-center  justify-content-center`}
                      >
                        <div
                          className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span>
                            Drag and drop an image or{" "}
                            <span className="color-blue">choose a file</span>{" "}
                            from your computer <br />
                            (allowed formats: .jpg, .png)
                          </span>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </>
          )}
          <div
            className={`col-12 mb-7 ${
              image || uploadedImage
                ? "d-flex flex-row align-items-center justify-content-center"
                : "d-none"
            }`}
          >
            <div
              className={`col-9   d-flex flex-column align-items-center justify-content-center `}
            >
              {image && (
                <>
                  <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                    <div className={`${styles.previewImg}`}>
                      <img
                        src={URL.createObjectURL(image)}
                        className={`${styles.previewImg}`}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                      <span className="text-xl">{image.name}</span>
                    </div>
                    <div className=" d-flex align-items-center justify-content-end ">
                      <div
                        className={` ${styles.removeButton}`}
                        onClick={() => setImage(undefined)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark as IconProp}
                          className=" text-16 me-0"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {uploadedImage && !image && (
                <>
                  <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                    <div className={`${styles.previewImg}`}>
                      <img
                        src={uploadedImage}
                        className={`${styles.previewImg}`}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                      <span className="text-xl"></span>
                    </div>
                    <div className=" d-flex align-items-center justify-content-end ">
                      <div
                        className={` ${styles.removeButton}`}
                        onClick={() => setUploadedImage("")}
                      >
                        <FontAwesomeIcon
                          icon={faXmark as IconProp}
                          className=" text-16 me-0"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {image && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12 mb-5">
              <div
                className="btn-success rounded  "
                onClick={() => uploadImage()}
              >
                Add Image
              </div>
            </div>
          )}
          <div className="col-12 text-center sub-title ">logo: </div>
          {!logo && !uploadedLogo && (
            <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-5">
              <div
                className=" mt-3 bg-light col-7 d-flex flex-row align-items-center justify-content-center"
                style={{
                  border: "1px dashed black",
                  borderRadius: "20px",
                }}
              >
                <Dropzone
                  onDrop={onDropLogo}
                  accept={"image/png, image/jpg, image/jpeg"}
                  maxSize={31457280}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className={` bg-light d-flex align-items-center  justify-content-center`}
                    >
                      <div
                        className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <span>
                          Drag and drop an image or{" "}
                          <span className="color-blue">choose a file</span> from
                          your computer <br />
                          (allowed formats: .jpg, .png)
                        </span>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
          <div
            className={`col-12 mb-7 ${
              logo || uploadedLogo
                ? "d-flex flex-row align-items-center justify-content-center"
                : "d-none"
            }`}
          >
            <div
              className={`col-9   d-flex flex-column align-items-center justify-content-center `}
            >
              {logo && (
                <>
                  <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                    <div className={`${styles.previewImg}`}>
                      <img
                        src={URL.createObjectURL(logo)}
                        className={`${styles.previewImg}`}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                      <span className="text-xl">{logo.name}</span>
                    </div>
                    <div className=" d-flex align-items-center justify-content-end ">
                      <div
                        className={` ${styles.removeButton}`}
                        onClick={() => setLogo(undefined)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark as IconProp}
                          className=" text-16 me-0"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {uploadedLogo && !logo && (
                <>
                  <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                    <div className={`${styles.previewImg}`}>
                      <img
                        src={uploadedLogo}
                        className={`${styles.previewImg}`}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                      <span className="text-xl"></span>
                    </div>
                    <div className=" d-flex align-items-center justify-content-end ">
                      <div
                        className={` ${styles.removeButton}`}
                        onClick={() => setUploadedLogo("")}
                      >
                        <FontAwesomeIcon
                          icon={faXmark as IconProp}
                          className=" text-16 me-0"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {logo && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12 mb-5">
              <div
                className="btn-success rounded  "
                onClick={() => uploadLogo()}
              >
                Add Logo
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UpdateContact;
