import axios from "axios";
import { setUser } from "../store/BusinessCardStore";
import {
  ContactData,
  LoginData,
  PrecontactData,
  SignupData,
  uploadImageData,
} from "../global";

import axiosInstance from "./axiosConfig";
import { jwtDecode } from "jwt-decode";

const TOKEN = "business_token";
const REFRESHTOKEN = "business_refreshtoken";

class axiosHelper {
  isLoggedIn = false;
  hasToken = false;

  constructor() {
    console.log("constructor di axioshelper");

    let currentDate = new Date();

    const token = localStorage.getItem(TOKEN);
    const decodedToken = token && jwtDecode(token as string);

    if (token && decodedToken) {
      if (decodedToken.exp && decodedToken.exp * 1000 < currentDate.getTime()) {
        // console.log("il token è scaduto");
        this.refreshTokens();
      } else {
        // console.log("token valido");
        this.tokLogin();
      }
    } else {
      // console.log("non ho mai avuto un token");
    }
  }

  setAuthToken(token: string, permanently: boolean) {
    // console.log(token);
    this.hasToken = true;
    // axios.defaults.headers.common["token"] = token;
    localStorage.removeItem(TOKEN);
    if (permanently) {
      localStorage.setItem(TOKEN, token);
    }
  }

  setRefreshToken(token: string, permanently: boolean) {
    // console.log(token);
    this.hasToken = true;
    localStorage.removeItem(REFRESHTOKEN);
    // axios.defaults.headers.common["token"] = token;
    if (permanently) {
      localStorage.setItem(REFRESHTOKEN, token);
    }
  }

  tokLogin() {
    // console.log(axios.defaults.headers);

    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/tokenLogin`,
        {
          token: localStorage.getItem(TOKEN),
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log("TOK LOGIN OK");
        // console.log(response.data);
        setUser(response.data);

        // setUserLogged(true);
        // setUser(response.data);
        return response.data;
      })
      .catch((e) => {
        // this.refreshTokens();
        this.refreshTokens();
        console.log("TOK LOGIN ERROR " + e);
        // setUserLogged(false);
      });
  }

  login(data: LoginData) {
    localStorage.removeItem(TOKEN);
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/login`,
        {
          email: data.email,
          password: data.password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        this.setAuthToken(response.data.token, true);
        this.setRefreshToken(response.data.refreshToken, true);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  refreshTokens() {
    try {
      return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/user/refreshtoken`, {
          refreshToken: localStorage.getItem(REFRESHTOKEN),
        })
        .then((response) => {
          console.log(response);
          this.setAuthToken(response.data.accessToken, true);
          this.setRefreshToken(response.data.refreshToken, true);
          return response.data;
        })
        .catch((error) => {
          console.log("refresh error" + error);
        });
    } catch (err) {
      console.log(err);
    }
  }

  signup(data: SignupData) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/user/signup`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  newContact(data: ContactData) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/contact`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContactForPages(page: number) {
    return axios
      .get<ContactData[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/page/${page}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getPrecontactForPages(page: number) {
    return axios
      .get<PrecontactData[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/precontact/page/${page}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContactByUuid(uuid: string) {
    return axios
      .get<ContactData>(`${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateContact(data: ContactData) {
    return axios
      .put<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/basicUpdate/${data.uuid}`,
        data
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteContact(uuid?: string, idUser?: number) {
    if (uuid && idUser) {
      return axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`, {
          data: { idUser: idUser },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  addContactImage(data: uploadImageData) {
    return axios
      .put<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/addImage/${data.uuid}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addContactLogo(data: uploadImageData) {
    return axios
      .put<ContactData>(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/addLogo/${data.uuid}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createContactFromPrecontact(uuid: string) {
    return axiosInstance
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/precontact/becomeContact/${uuid}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new axiosHelper();
