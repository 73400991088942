import { useNavigate } from "react-router";

import styles from "./ContactRow.module.scss";
import { ContactData } from "../../global";
import { setCurrentContact } from "../../store/BusinessCardStore";

interface ContactRowProps {
  contact: ContactData;
}

const ContactRow: React.FC<ContactRowProps> = (props) => {
  const redirect = useNavigate();

  return (
    <>
      <div
        className={`col-12 d-flex flex-row align-items-center justify-content-between p-3 border mt-3 `}
        style={{ borderRadius: "10px" }}
        onClick={() => {
          setCurrentContact(props.contact);
          redirect(`/${props.contact.uuid}/update`);
        }}
      >
        <div className="col-4 text-s text-start">{props.contact.uuid}</div>
        <div className="col-3 text-s text-start">{props.contact.name}</div>
        <div className="col-3 text-s text-start">{props.contact.surname}</div>
      </div>
    </>
  );
};

export default ContactRow;
