import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore from "../../store/BusinessCardStore";
import { getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SignupData } from "../../global";
import axiosHelper from "../../helpers/axiosHelper";

const Registration: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);

  const redirect = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupData>();

  const registration = async (data: SignupData) => {
    await axiosHelper
      .signup(data)
      .then((response: any) => {
        console.log(response);
        redirect("/");
      })
      .catch((error: any) => {
        console.log(error);
        // console.log(error.response.status);
      });
  };

  const onSubmit = async (data: SignupData) => {
    console.log(data);
    registration(data);
  };

  return (
    <>
      <Header user={user.username} page={"REGISTRATION"} />

      <main className="containter-md col-12  " style={{ height: "90vh" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex mt-7 flex-row align-items-center justify-content-center   ">
            <h1 className="bold-title"> Login</h1>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              name :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("name")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              surname :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("surname")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              username :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("username")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              email :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("email")}
              />
            </div>
          </div>

          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              password :
            </div>
            <div className="col-5 ">
              <input
                type="password"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("password")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              type :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("type")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              code :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("code")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row    align-items-center justify-content-center mt-7 ">
            <button className="btn-blue col-4  rounded">registra</button>
          </div>
        </form>
      </main>
    </>
  );
};

export default Registration;
