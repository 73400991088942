import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/BusinessCardStore";
import axiosHelper from "../../helpers/axiosHelper";
import { LoginData } from "../../global";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

const Login: React.FC = () => {
  const redirect = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();

  const login = async (data: LoginData) => {
    await axiosHelper
      .login(data)
      .then((response: any) => {
        console.log(response);
        setUser(response.user);
        redirect("/");
      })
      .catch((error: any) => {
        console.log(error);
        // console.log(error.response.status);
      });
  };

  const onSubmit = async (data: LoginData) => {
    console.log(data);
    login(data);
  };
  return (
    <>
      <main className="containter-md col-12  " style={{ height: "90vh" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div></div>
          <div className="d-flex mt-7 flex-row align-items-center justify-content-center   ">
            <h1 className="bold-title"> Login</h1>
          </div>
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              email :
            </div>
            <div className="col-5 ">
              <input
                type="text"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("email")}
              />
            </div>
          </div>

          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-6  me-5">
              password :
            </div>
            <div className="col-5 ">
              <input
                type="password"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  width: "300px",
                  border: "1px soldid black",
                }}
                {...register("password")}
              />
            </div>
          </div>
          <div className=" d-flex flex-row    align-items-center justify-content-center mt-7 ">
            <button className="btn-blue col-4  rounded">login</button>
          </div>
        </form>
      </main>
    </>
  );
};

export default Login;
