import { Store } from "pullstate";
import { ContactData, PrecontactData, User } from "../global";

export interface IBusinessCardStore {
  user: User;
  currentContact: ContactData;
  contacts: ContactData[];
  precontacts: PrecontactData[];
  currentPrecontact: PrecontactData;
}

const BusinessCardStore = new Store<IBusinessCardStore>({
  user: {
    username: "",
    id: 0,
  },
  currentContact: {
    name: "",
    surname: "",
    email: "",
    phone: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    website: "",
    uuid: "",
    mode: "",
    logourl: "",
    imageurl: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    order_id: "",
  },
  contacts: [],
  precontacts: [],
  currentPrecontact: {
    uuid: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    website: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    mode: "",
    logourl: "",
    imageurl: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    order_id: "",
    activated: 0,
  },
});

export default BusinessCardStore;

export const setUser = (user: User) => {
  BusinessCardStore.update((s) => {
    s.user = user;
  });
};

export const setCurrentContact = (contact: ContactData) => {
  BusinessCardStore.update((s) => {
    s.currentContact = contact;
  });
};

export const setContacts = (contacts: ContactData[]) => {
  BusinessCardStore.update((s) => {
    s.contacts = contacts;
  });
};

export const setCurrentPrecontact = (precontact: PrecontactData) => {
  BusinessCardStore.update((s) => {
    s.currentPrecontact = precontact;
  });
};

export const setPrecontacts = (precontacts: PrecontactData[]) => {
  // console.log(precontacts);
  BusinessCardStore.update((s) => {
    s.precontacts = precontacts;
  });
};
