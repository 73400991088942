import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./Container";

function App() {
  
  return (
    <Router>
      <Container />
    </Router>
  );
}

export default App;
