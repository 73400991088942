import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import styles from "./UpdateContact.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setContacts,
  setCurrentContact,
  setPrecontacts,
} from "../../store/BusinessCardStore";
import {
  getCurrentContact,
  getCurrentPrecontact,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useForm } from "react-hook-form";
import { ContactData, uploadImageData } from "../../global";
import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate, useParams } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Dropzone from "react-dropzone";
import { Modal } from "react-bootstrap";

const UpdatePrecontact: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const currentPrecontact = useStoreState(
    BusinessCardStore,
    getCurrentPrecontact
  );
  const [error, setError] = useState("");
  const [whatsapp, setWhatsapp] = useState(0);
  const params = useParams();
  const redirect = useNavigate();
  const [contact, setContact] = useState<ContactData>();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [workmail, setWorkmail] = useState("");
  const [role, setRole] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [company, setCompany] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [label_link1, setLabel_link1] = useState("");
  const [label_link2, setLabel_link2] = useState("");
  const [mode, setMode] = useState("");
  const [logo, setLogo] = useState<File>();
  const [image, setImage] = useState<File>();
  const [uploadedImage, setUploadedImage] = useState("");
  const [uploadedLogo, setUploadedLogo] = useState("");

  const [popup, setPopup] = useState(false);
  const [phrase, setPhrase] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactData>();

  const onSubmit = async (data: ContactData) => {
    if (params.uuid) {
      if (whatsapp) {
        data.whatsapp = 1;
      } else {
        data.whatsapp = 0;
      }
      console.log(data);
      data.name = name;
      data.surname = surname;
      data.email = email;
      data.workmail = workmail;
      data.phone = phone;
      data.website = website;
      data.role = role;
      data.linkedin = linkedin;
      data.instagram = instagram;
      data.facebook = facebook;
      data.company = company;
      data.mode = mode;
      data.uuid = params.uuid;
      data.link1 = link1;
      data.link2 = link2;
      data.label_link1 = label_link1;
      data.label_link2 = label_link2;
      console.log(data);

      await axiosHelper.updateContact(data).then((response) => {
        console.log(response);
        redirect("/List");
      });
      // await axiosHelper
      //   .newContact(data)
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };

  useEffect(() => {
    if (params.uuid) {
      getContactFromParams();
    }
  }, [params.uuid]);

  const getContactFromParams = async () => {
    if (params.uuid) {
      await axiosHelper
        .getContactByUuid(params.uuid)
        .then((response: ContactData) => {
          console.log(response);
          setContact(response);
          setCurrentContact(response);
        });
    }
  };

  const makeContact = async () => {
    if (currentPrecontact.activated === 0) {
      await axiosHelper
        .createContactFromPrecontact(currentPrecontact.uuid)
        .then(async (response) => {
          console.log(response);
          await axiosHelper.getPrecontactForPages(0).then((response) => {
            setPrecontacts(response);
            redirect("/Precontactlist");
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Header user={user.username} page={"UPDATE CONTACT"} />
      {!user.username && <Login />}
      {user.username && (
        <>
          <div className="d-flex flex-row align-items-center justify-content-start">
            <span
              className="d-flex flex-row align-items-center justify-content-center border bg-white cursor-pointer "
              onClick={() => redirect("/PrecontactList")}
              style={{ height: "40px", width: "100px", borderRadius: "15px" }}
            >
              BACK TO LIST
            </span>
          </div>
          <div
            className={`p-5 d-flex flex-column align-items-center justify-content-start ${
              currentPrecontact.activated === 0 ? "bg-blue" : "bg-success"
            }`}
          >
            <div className="d-flex flex-row align-items-center justify-content-between col-12 ">
              <span className="fw-bold">UUID</span>
              <span>{currentPrecontact.uuid}</span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">Name</span>
              <span>{currentPrecontact.name}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">surname</span>
              <span>{currentPrecontact.surname}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">phone</span>
              <span>{currentPrecontact.phone}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">email</span>
              <span>{currentPrecontact.email}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">work mail</span>
              <span>{currentPrecontact.workmail}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">role</span>
              <span>{currentPrecontact.role}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">website</span>
              <span>{currentPrecontact.website}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">linkedin</span>
              <span>{currentPrecontact.linkedin}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">facebook</span>
              <span>{currentPrecontact.facebook}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">instagram</span>
              <span>{currentPrecontact.instagram}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">whatsapp</span>
              <span>{currentPrecontact.whatsapp === 1 ? "yes" : "no"}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">image</span>
              <span>{currentPrecontact.imageurl}</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between col-12  mt-3">
              <span className="fw-bold">logo</span>
              <span>{currentPrecontact.logourl}</span>
            </div>
          </div>
          {currentPrecontact.activated === 0 && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
              <div
                className=" col-3 btn-success rounded cursor-pointer"
                onClick={() => makeContact()}
              >
                CREATE CONTACT
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UpdatePrecontact;
