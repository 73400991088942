import { useNavigate } from "react-router";

import styles from "./PrecontactRow.module.scss";
import { PrecontactData } from "../../global";
import { setCurrentPrecontact } from "../../store/BusinessCardStore";

interface ContactRowProps {
  precontact: PrecontactData;
}

const PrecontactRow: React.FC<ContactRowProps> = (props) => {
  const redirect = useNavigate();

  return (
    <>
      <div
        className={`col-12 d-flex flex-row cursor-pointer align-items-center justify-content-between p-3 border mt-3 ${
          props.precontact.activated ? "bg-success" : "bg-blue"
        }`}
        style={{ borderRadius: "10px" }}
        onClick={() => {
          setCurrentPrecontact(props.precontact);
          redirect(`/precontact/${props.precontact.uuid}/update`);
        }}
      >
        <div className="col-4 text-s text-start">{props.precontact.uuid}</div>
        <div className="col-3 text-s text-start">{props.precontact.name}</div>
        <div className="col-3 text-s text-start">
          {props.precontact.surname}
        </div>
      </div>
    </>
  );
};

export default PrecontactRow;
