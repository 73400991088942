import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home/Home";
import AddContact from "./routes/AddContact/AddContact";
import ContactList from "./routes/ContactList/ContactList";
import UpdateContact from "./routes/UpdateContact/UpdateContact";
import Registration from "./routes/Registration/Registration";
import PrecontactList from "./routes/PrecontactList/PrecontactList";
import UpdatePrecontact from "./routes/UpdatePrecontact/UpdatePrecontact";

const Container = () => {
  return (
    <Routes>
      <Route path={`/`} element={<Home />} />
      <Route path={`/Add`} element={<AddContact />} />
      <Route path={`/Contactlist`} element={<ContactList />} />
      <Route path={`/Precontactlist`} element={<PrecontactList />} />
      <Route path={`/:uuid/update`} element={<UpdateContact />} />
      <Route path={`/precontact/:uuid/update`} element={<UpdatePrecontact />} />
      <Route path={`/registra`} element={<Registration />} />
    </Routes>
  );
};
export default Container;
