import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, { setContacts } from "../../store/BusinessCardStore";
import { getContacts, getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { ContactData } from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";

const ContactList: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const contacts = useStoreState(BusinessCardStore, getContacts);

  const redirect = useNavigate();

  const [page, setPage] = useState(0);

  //   useEffect(() => {
  //     getContactForCurrentPage();
  //   }, []);

  useEffect(() => {
    getContactForCurrentPage();
  }, [page]);

  const getContactForCurrentPage = async () => {
    await axiosHelper
      .getContactForPages(page)
      .then((response: ContactData[]) => {
        console.log(response);
        setContacts(response);
      });
  };

  return (
    <>
      <Header user={user.username} page={"LISTA CONTATTI"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            {contacts.map((item: ContactData) => (
              <ContactRow contact={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ContactList;
